import React from 'react';
import PropTypes from 'prop-types';

import { GatsbySeo } from 'gatsby-plugin-next-seo/lib/meta/gatsby-seo';
import { Helmet } from 'react-helmet-async';

import { useSeo, useContact } from '@queries';
import { stripHtml } from '@helpers';

const SEO = props => {
  const contact = useContact();
  const seo = useSeo();

  const title = props.title || seo.title;
  const description = props.description || seo.description;

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={'%s • komornik-olsztyn.pl'}
        description={description}
        canonical={props.canonical ? props.canonical : undefined}
        openGraph={{
          url: 'https://komornik-olsztyn.pl/',
          title: title,
          description: description,
          images: props.images,
          site_name: `${stripHtml(contact.title)} ${contact.name}, ${
            contact.institution
          }`,
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
      />
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
      />
    </>
  );
};

SEO.defaultProps = {
  description: '',
  images: [],
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      width: PropTypes.number,
      height: PropTypes.number,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default SEO;
