import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image/withIEPolyfill';

import css from '@emotion/css';
import tw from 'twin.macro';

import { Container, Heading, Subheading, Small, Cta } from '@components';

const Hero = ({ heroImg, title, name, institution, idEpu, phoneNumber, email }) => (
    <section
        css={tw`relative w-full lg:h-screen py-32 md:py-40 flex flex-col justify-center items-center before:content shadow-sm transition-all duration-300 ease-in`}
    >
        <Img
            fluid={heroImg.asset.fluid}
            alt="Zdjęcie Temidy"
            objectFit="cover"
            objectPosition="80% 0%"
            css={tw`absolute! inset-0 -z-1 block w-full h-full`}
        />
        <Container css={tw`xl:max-w-screen-xl to-md:text-center`}>
            <Heading
                as="h1"
                css={tw`md:w-2/3 mb-4`}
                dangerouslySetInnerHTML={{ __html: title }}
            />
            <Subheading>{name}</Subheading>
            <Small>{institution}</Small>
            <Small>ID EPU: {idEpu}</Small>
            <div
                css={tw`flex to-md:justify-center to-xs:flex-col mt-12 to-xs:mx-auto to-xs:w-2/3`}
            >
                <Cta to="/kontakt" css={tw`mr-0 xs:mr-12 to-xs:mb-6`}>
                    Kontakt
                </Cta>
                <Cta to="/wnioski" outline>
                    Wnioski
                </Cta>
            </div>
        </Container>
        <Container
            css={tw`absolute to-md:static bottom-0 mt-20 pb-4 xl:max-w-screen-xl to-md:text-center`}
        >
            <Small>{phoneNumber}</Small>
            <Small>{email}</Small>
        </Container>

        <div
            css={css`
        ${tw`
        absolute inset-0 -z-1
        block
        `}
        @media only screen and (max-width: 1024px) {
          background-color: rgba(255, 255, 255, 0.75);
        }
      `}
        />
    </section>
);

Hero.propTypes = {
    heroImg: PropTypes.shape({
        fluid: PropTypes.object,
    }).isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    institution: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

export default Hero;
