import React from 'react';
import PropTypes from 'prop-types';

import shortid from 'shortid';

import tw from 'twin.macro';

import { Subheading } from '@components';

const HoursTable = ({ data }) => (
  <table css={tw`inline-block text-left`}>
    <tbody>
      {data.rows.map(row => (
        <tr key={shortid.generate()}>
          {row.cells.map(cell => (
            <td key={shortid.generate()} css={tw`even:text-right even:pl-6`}>
              <Subheading>{cell}</Subheading>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

HoursTable.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        cells: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }).isRequired,
};

export default HoursTable;
