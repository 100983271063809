import React from 'react';

import { Link } from 'gatsby';

import tw from 'twin.macro';

import logoSVG from '@images/logo.svg';

const Logo = () => (
  <Link to="/">
    <img
      src={logoSVG}
      alt="Przejdź na stronę główną"
      css={tw`h-12 xs:h-16 lg:h-20 fill-current`}
    />
  </Link>
);

export default Logo;
