import React, { useRef, useEffect } from 'react';

import css from '@emotion/css';
import tw from 'twin.macro';

import '@tomtom-international/web-sdk-maps/dist/maps.css';
import tt from '@tomtom-international/web-sdk-maps';

const TOMTOM_API_KEY = process.env.GATSBY_TOMTOM_API_KEY;

const ContactMap = () => {
  const mapRef = useRef();

  useEffect(() => {
    const center = [20.47872, 53.77827];

    const map = tt.map({
      key: TOMTOM_API_KEY,
      container: mapRef.current,
      center,
      zoom: 17,
      scrollZoom: false,
      dragRotate: false,
      dragPan: false,
      doubleClickZoom: false,
      touchZoomRotate: false,
      touchPitch: false,
      fadeDuration: 100,
    });

    new tt.Marker().setLngLat(center).addTo(map);

    return () => map.remove();
  }, []);

  return (
    <div
      css={css`
        ${tw`overflow-hidden w-full mt-10 border-t-2 shadow-md border-accent rounded-br-default pointer-events-none`};
        filter: grayscale(80%);
        height: 350px;
      `}
      ref={mapRef}
    ></div>
  );
};

export default ContactMap;
