import React from 'react';
import PropTypes from 'prop-types';
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';

import { Link } from 'gatsby';

import shortid from 'shortid';

import css from '@emotion/css';
import tw from 'twin.macro';

const Pagination = ({
  numOfPages,
  currentPage,
  nextPageLink,
  prevPageLink,
}) => {
  const arrowStyle = tw`flex justify-center items-center w-10 h-10 mx-3 text-2xl text-accent rounded-br-default`;
  const disableArrowStyle = tw`pointer-events-none opacity-25`;

  return (
    <div css={tw`flex to-md:justify-between mb-20`}>
      <Link
        to={`/${prevPageLink}`}
        aria-label={
          prevPageLink ? `Przejdź na stronę nr ${currentPage}` : undefined
        }
        tabIndex={!prevPageLink ? '-1' : undefined}
        aria-hidden={!prevPageLink ? true : undefined}
        css={css`
          ${arrowStyle}
          ${!prevPageLink && disableArrowStyle}
        `}
      >
        <FaLongArrowAltLeft />
      </Link>
      <ul css={tw`flex`}>
        {[...Array(numOfPages)].map((_, pageNum) => (
          <li key={shortid.generate()}>
            <Link
              to={`/licytacje${pageNum ? `/${pageNum + 1}` : ''}`}
              css={tw`flex justify-center items-center w-10 h-10 mx-2 bg-white border-2 border-accent rounded-br-default underline`}
              activeStyle={tw`font-bold bg-accent text-white shadow-lg transform -translate-y-px`}
            >
              {pageNum + 1}
            </Link>
          </li>
        ))}
      </ul>
      <Link
        to={`/${nextPageLink}`}
        aria-label={
          nextPageLink ? `Przejdź na stronę nr ${currentPage + 2}` : undefined
        }
        tabIndex={!nextPageLink ? '-1' : undefined}
        aria-hidden={!nextPageLink ? true : undefined}
        css={css`
          ${arrowStyle}
          ${!nextPageLink && disableArrowStyle}
        `}
      >
        <FaLongArrowAltRight />
      </Link>
    </div>
  );
};

Pagination.propTypes = {
  numOfPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  nextPageLink: PropTypes.string,
  prevPageLink: PropTypes.string,
};

export default Pagination;
