import tw from 'twin.macro';

const Column = tw.div`
  w-1/2 to-md:w-full
  md:px-5
  last:to-sm:mt-10
  sm-md:first:mr-2
  sm-md:last:ml-2
  sm-md:text-left
  sm:last:text-right
`;

export default Column;
