import React from 'react';
import PropTypes from 'prop-types';

import css from '@emotion/css';
import tw from 'twin.macro';

import { Container, Heading } from '@components';

const Section = ({ children, id, heading, spaceTop, main }) => (
  <section
    id={id}
    css={css`
      ${tw`py-20 md:py-32`};
      ${spaceTop && tw`mt-16`};
    `}
  >
    <Container css={tw`to-md:text-center`}>
      <Heading
        as={main && 'h1'}
        css={tw`relative inline-block mb-12 md:mb-20 after:content after:inline-block after:w-3 after:h-3 after:bg-accent after:ml-3 after:rounded-br-default`}
      >
        {heading}
      </Heading>
      {children}
    </Container>
  </section>
);

Section.defaultProps = {
  spaceTop: false,
  main: false,
};

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  id: PropTypes.string,
  heading: PropTypes.string.isRequired,
  spaceTop: PropTypes.bool,
  main: PropTypes.bool,
};

export default Section;
