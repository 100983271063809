import { graphql, useStaticQuery } from 'gatsby';

const useMotions = () => {
  const data = useStaticQuery(
    graphql`
      query MotionsQuery {
        sanityMotions {
          _rawText
          motions {
            title
            doc {
              asset {
                url
                originalFilename
              }
            }
            pdf {
              asset {
                url
                originalFilename
              }
            }
          }
        }
      }
    `
  );

  return data.sanityMotions;
};

export default useMotions;
