import React from 'react';

import tw from 'twin.macro';

import { Auction } from '@components';

const Auctions = ({ auctions }) => (
  <div
    css={tw`relative flex flex-col lg:flex-row justify-between flex-wrap pb-20`}
  >
    {auctions.map(({ id, title, _createdAt, images, slug }) => (
      <Auction
        key={id}
        title={title}
        createdAt={_createdAt}
        images={images}
        slug={slug.current}
      />
    ))}
  </div>
);

export default Auctions;
