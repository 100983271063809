import styled from '@emotion/styled';
import tw from 'twin.macro';

const Header = styled.header`
  ${tw`
  fixed top-0 left-1/2 z-40
  flex justify-between items-center
  w-11/12
  py-4
  xl:max-w-screen-xl
  transform -translate-x-1/2
  text-accent
  `}

  @media (min-width: 769px) {
    mix-blend-mode: multiply;
  }
`;

export default Header;
