import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import tw from 'twin.macro';

import BlockContent from '@sanity/block-content-to-react';

const Markdown = styled(BlockContent)`
  p {
    ${tw`text-lg md:text-xl pb-5 leading-relaxed`}
    ${({ centered }) => centered && tw`to-md:text-center!`}
    ${({ justify }) => justify && tw`text-justify!`}
  }

  a {
    ${tw`underline text-accent`}
  }

  & ul {
    ${tw`list-disc mb-4 pl-10`}

    & li {
      ${tw`mb-3 text-lg md:text-xl text-left`}
    }
  }

  & h3 {
    ${tw`text-2xl md:text-3xl font-bold text-secondary`}
  }

  & strong {
    ${tw`font-bold`}
  }

  & em {
    ${tw`text-accent font-light`}
  }
`;

Markdown.defaultProps = {
  renderContainerOnSingleChild: true,
  centered: false,
  justify: true,
};

Markdown.propTypes = {
  centered: PropTypes.bool,
};

export default Markdown;
