import React from 'react';
import PropTypes from 'prop-types';

import { FaLongArrowAltRight } from 'react-icons/fa';

import { Link } from 'gatsby';

import tw from 'twin.macro';

import { Section, Auctions } from '@components';

const AuctionSection = ({ auctions }) => (
  <Section heading="Najnowsze Licytacje">
    <Auctions auctions={auctions} />
    <div css={tw`flex items-center justify-center flex-no-wrap pt-3`}>
      <span css={tw`block w-full h-px bg-accent flex-1`} />
      <Link
        to="/licytacje"
        css={tw`ml-5 underline md:text-xl text-accent text-right font-bold hocus:opacity-75 transition-opacity duration-200 ease-in-out`}
      >
        Pozostałe Licytacje
        <FaLongArrowAltRight css={tw`inline-block ml-2`} />
      </Link>
    </div>
  </Section>
);

AuctionSection.propTypes = {
  auctions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      _createdAt: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          asset: PropTypes.shape({
            id: PropTypes.string.isRequired,
            fluid: PropTypes.object.isRequired,
          }),
        })
      ),
    })
  ),
};

export default AuctionSection;
