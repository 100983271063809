import { graphql, useStaticQuery } from 'gatsby';

const useSeo = () => {
  const data = useStaticQuery(
    graphql`
      query SeoQuery {
        sanitySeo {
          title
          description
          description_about
          description_jurisdiction
          description_auctions
        }
      }
    `
  );

  return data.sanitySeo;
};

export default useSeo;
