import React from 'react';
import PropTypes from 'prop-types';

import { MdErrorOutline } from 'react-icons/md';

import tw from 'twin.macro';

import { Subheading } from '@components';

const ErrorMsg = ({ msg, ...props }) => (
  <Subheading css={tw`w-full`} {...props}>
    <MdErrorOutline css={tw`inline-block text-accent mr-2 mb-1`} />
    {msg}
  </Subheading>
);

ErrorMsg.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default ErrorMsg;
