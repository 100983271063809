import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import Img from 'gatsby-image';

import tw from 'twin.macro';

import { Subheading, Small } from '@components';

const Auction = ({ title, createdAt, images, slug }) => (
  <article
    css={tw`bg-white relative flex flex-col md-lg:flex-row justify-between w-full lg:w-7/15 mb-10 p-8 shadow-lg to-md:border-t-2 md-lg:border-l-4 lg:border-t-2 border-accent rounded-br-default transition-all duration-200 ease-in-out transform hocus:-translate-y-px hocus:shadow-xl`}
  >
    {!!images.length && (
      <div
        css={tw`flex md-lg:flex-col w-full md-lg:w-1/3 h-40 sm:h-48 md-lg:h-full mb-5 md-lg:mr-10 shadow-md`}
      >
        {images.slice(0, 2).map(image => (
          <Img
            key={image.asset.id}
            fluid={image.asset.fluid}
            objectFit="cover"
            css={tw`w-full h-auto md-lg:h-32`}
          />
        ))}
      </div>
    )}
    <div css={tw`flex-1 flex flex-col justify-between`}>
      <div>
        <Small>Opublikowano: {createdAt}</Small>
        <Subheading css={tw`mt-2 mb-4 break-words`}>{title}</Subheading>
      </div>
      <Link
        to={`/licytacje/${slug}`}
        css={tw`underline text-accent focus:opacity-75 font-bold before:content before:absolute before:inset-0 z-30`}
      >
        Zobacz więcej...
      </Link>
    </div>
  </article>
);

Auction.defaultProps = {
  images: [],
};

Auction.propTypes = {
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      asset: PropTypes.shape({
        id: PropTypes.stringisRequired,
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ),
  slug: PropTypes.string.isRequired,
};

export default Auction;
