import { Link } from 'gatsby';

import tw from 'twin.macro';

const NavbarItem = tw(Link)`
  inline-block
  text-lg
  text-accent
  text-center
  capitalize
  transition-opacity duration-200 ease-in
  hocus:opacity-75 

  md:no-underline
  ml-0
  mb-5 last:mb-0
  md:relative  
  md:ml-8 lg:ml-10 first:ml-0

  to-md:before:hidden
  before:content
  before:absolute
  before:bottom-0
  before:left-0
  before:block
  before:w-full
  before:h-px hocus:before:-translate-y-px
  before:transform before:transition-transform before:duration-200 before:ease-in-out
  before:bg-accent
`;

export default NavbarItem;
