import { graphql, useStaticQuery } from 'gatsby';

const useSiteSettings = () => {
    const data = useStaticQuery(
        graphql`
query SiteSettingsQuery {
    sanitySiteSettings {
        heroImg {
            asset {
                fluid(maxWidth: 2560) {
                    ...GatsbySanityImageFluid_withWebp
                }
            }
        }
        disclaimer
        copyright
        menu {
            option
        }
        _rawNews
        _rawClauseText
        clauseFile {
            asset {
                url
                originalFilename
            }
        }
    }
}
`
    );

    return data.sanitySiteSettings;
};

export default useSiteSettings;
