import { graphql, useStaticQuery } from 'gatsby';

const useContact = () => {
    const data = useStaticQuery(
        graphql`
query ContactQuery {
    sanityContact {
        name
        title
        institution
        idEpu
        bankingAccount
        email
        phoneNumber
        address_city
        address_street
        workingHours {
            rows {
                cells
            }
        }
        acceptingHours {
            rows {
                cells
            }
        }
    }
}
`
    );

    return data.sanityContact;
};

export default useContact;
