import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import styled from '@emotion/styled';
import tw from 'twin.macro';

const Cta = styled(({ outline, ...props }) => <Link {...props} />)`
  ${tw`
  bg-accent
  px-8 py-2
  font-bold
  leading-8
  text-xl
  text-white
  rounded-br-default
  box-border
  hocus:opacity-75
  transform hocus:-translate-y-px
  transition-all duration-200 ease-in-out
  shadow-lg hocus:shadow-xl
  no-underline!
  `}

  ${({ outline }) =>
    outline &&
    tw`bg-transparent border-accent border-2 text-accent shadow-none hocus:shadow-none`}
`;

Cta.defaultProps = {
  outline: false,
};

Cta.propTypes = {
  outline: PropTypes.bool,
};

export default Cta;
