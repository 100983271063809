export default string => {
  const accents =
    'ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØÓòóôõöøóÈÉÊËĘèéêëęðÇĆçćÐÌÍÎÏìíîïŁłÙÚÛÜùúûüÑŃñńŠŚšśŸÿýŽŻŹžżź';
  const accentsOut =
    'AAAAAAAaaaaaaaBOOOOOOOOoooooooEEEEEeeeeeeCCccDIIIIiiiiLlUUUUuuuuNNnnSSssYyyZZZzzz';
  return string
    .split('')
    .map((letter, index) => {
      const accentIndex = accents.indexOf(letter);
      return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
    })
    .join('');
};
