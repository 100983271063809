import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { MdClose, MdMenu } from 'react-icons/md';

import css from '@emotion/css';
import tw from 'twin.macro';

import shortid from 'shortid';

import { NavbarItem } from '@components';
import { urlify } from '@helpers';

const Navbar = ({ menu }) => {
  const [isOpen, setIsOpen] = useState(false);

  useScrollPosition(
    ({ prevPos, nextPos }) => prevPos !== nextPos && isOpen && setIsOpen(false)
  );

  return (
    <>
      <button
        css={tw`focus:outline-none bg-white text-accent p-3 text-2xl shadow-xl z-50 rounded-full fill-current md:hidden`}
        aria-label="Menu"
        aria-hidden="true"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen ? <MdMenu /> : <MdClose />}
      </button>
      <nav
        css={css`
          @media (max-width: 768px) {
            ${tw`
            fixed top-full right-0 z-0
            flex flex-col items-center
            w-1/2 to-sm:w-full 
            p-20 pt-24 to-sm:p-10 
            shadow-lg 
            bg-white 
            rounded-br-default
            transition-all duration-200 ease-in-out
            `}

            transform: scale(${!isOpen ? '0' : '1'});
            transform-origin: top right;
            opacity: ${!isOpen ? '0' : '1'};
          }`}
      >
        {menu.map(({ option, isHash }) => (
          <NavbarItem
            key={shortid.generate()}
            to={`/${urlify(option)}`}
            activeStyle={tw`before:hidden! text-primary`}
            onClick={() => isOpen && setIsOpen(false)}
          >
            {option}
          </NavbarItem>
        ))}
      </nav>
    </>
  );
};

Navbar.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Navbar;
