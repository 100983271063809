import { graphql, useStaticQuery } from 'gatsby';

const useAbout = () => {
  const data = useStaticQuery(
    graphql`
      query AboutQuery {
        sanityAbout {
          _rawContent
        }
      }
    `
  );

  return data.sanityAbout;
};

export default useAbout;
