import tw from 'twin.macro';

const Subheading = tw.h3`
  text-secondary
  to-md:text-xl text-2xl
  font-medium
  leading-relaxed
`;

export default Subheading;
