import React from 'react';
import PropTypes from 'prop-types';

import { Section, Markdown, ErrorMsg } from '@components';

const News = ({ news }) => (
  <Section heading="Aktualności">
    {news ? <Markdown blocks={news} /> : <ErrorMsg msg="Brak aktualności" />}
  </Section>
);

News.propTypes = {
  news: PropTypes.array,
};

export default News;
