import { graphql, useStaticQuery } from 'gatsby';

const useJurisdiction = () => {
  const data = useStaticQuery(
    graphql`
      query JurisdictionQuery {
        sanityJurisdiction {
          _rawContent
          map {
            asset {
              fluid(maxWidth: 1280) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    `
  );

  return data.sanityJurisdiction;
};

export default useJurisdiction;
