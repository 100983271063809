import React from 'react';

import { MdContactPhone, MdInsertDriveFile, MdGavel } from 'react-icons/md';

import tw from 'twin.macro';

import { Option } from '@components';

const MobileNav = () => (
  <nav
    css={tw`
      fixed bottom-0 left-0 right-0 z-30
      hidden to-md:flex justify-around
      w-full
      pt-2 sm:pt-3 pb-1 sm:pb-2 px-20 to-xs:px-0 
      bg-white
      shadow-inner
    `}
  >
    <Option to="/kontakt" icon={MdContactPhone} option="Kontakt" />
    <Option to="/wnioski" icon={MdInsertDriveFile} option="Wnioski" />
    <Option to="/licytacje" icon={MdGavel} option="Licytacje" />
  </nav>
);

export default MobileNav;
