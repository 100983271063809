import { graphql, useStaticQuery } from 'gatsby';

const useAuctionSection = () => {
  const data = useStaticQuery(
    graphql`
      query AuctionSectionQuery {
        allSanityAuction(sort: { order: DESC, fields: _createdAt }, limit: 2) {
          edges {
            node {
              id
              title
              _createdAt(formatString: "DD.MM.YYYY")
              slug {
                current
              }
              images {
                asset {
                  id
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return data.allSanityAuction.edges.map(edge => edge.node);
};

export default useAuctionSection;
