import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import tw from 'twin.macro';

const Option = ({ icon: Icon, option, href, to, ...props }) => {
    const styles = {
        css: tw`
      relative
      block
      ml-8 lg:ml-10 to-md:ml-0 first:ml-0
      text-lg to-sm:text-sm
      text-center
      text-accent
      transition-opacity duration-300 ease-in-out
      hocus:opacity-75
      break-normal
      no-underline
    `,
        activeStyle: tw`
      text-secondary
      underline
    `,
    };

    const Wrapper = ({ children }) =>
        href ? (
            <a href={href} css={styles.css} {...props}>
                {children}
            </a>
        ) : (
            <Link to={to} {...styles} {...props}>
                {children}
            </Link>
        );

    return (
        <Wrapper>
            <>
                {Icon && (
                    <Icon
                        css={tw`
          text-2xl to-sm:text-xl
          mx-auto mb-1
        `}
                    />
                )}
                <span css={tw`block`}>{option}</span>
            </>
        </Wrapper>
    );
};

Option.propTypes = {
    icon: PropTypes.func,
    option: PropTypes.string.isRequired,
    to: PropTypes.string,
    href: PropTypes.string,
};

export default Option;
