import tw from 'twin.macro';

const Heading = tw.h2`
  text-3xl md:text-4xl
  text-primary
  font-bold
  leading-snug
`;

export default Heading;
