import React from 'react';

import { Global, css } from '@emotion/core';
import tw from 'twin.macro';

import { MdFileDownload } from 'react-icons/md';

import {
    SEO,
    Header,
    Navbar,
    MobileNav,
    Footer,
    Logo,
    Container,
    Row,
    Column,
    Heading,
    Paragraph,
    Subheading,
    Small,
    Markdown
} from '@components';
import { useSiteSettings, useContact } from '@queries';

const Layout = ({ children, spaceTop, ...props }) => {
    const {
        title,
        name,
        institution,
        idEpu,
        bankingAccount,
        phoneNumber,
        email,
        address_street,
        address_city,
    } = useContact();
    const { menu, copyright, disclaimer, _rawClauseText, clauseFile } = useSiteSettings();

    return (
        <>
            <Global
                styles={css`
          html {
            ${tw`font-display text-primary tracking-wide overflow-x-hidden`}
            overflow-wrap: anywhere;
          }

          ::selection {
            background: rgba(200, 200, 200, 0.5);
          }

          a {
            text-decoration: underline;
          }
        `}
            />
            <SEO {...props} />

            <Header
                css={css`
          top: 0;
        `}
            >
                <Logo />
                <Navbar menu={menu} />
            </Header>
            <MobileNav />

            <main id="main">{children}</main>

            <Footer>
                <Container css={tw`xl:max-w-screen-xl`}>
                    <Row css={tw`m-0`}>
                        <Column
                            css={tw`flex flex-col justify-between to-sm:items-center to-sm:text-center sm:pr-20!`}
                        >
                            <div>
                                <Heading
                                    as="h3"
                                    css={tw`text-2xl! mb-5 text-white`}
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                                <Subheading as="h4" css={tw`text-xl! text-white`}>
                                    {name}
                                </Subheading>
                                <Small css={tw`text-white`}>{institution}</Small>
                                <Small css={tw`text-white`}>ID EPU: {idEpu}</Small>
                            </div>
                            <div css={tw`mt-8 md:mt-10`}>
                                <Small css={tw`text-white`}>{bankingAccount}</Small>
                                <Small css={tw`text-white`}>{phoneNumber}</Small>
                                <Small css={tw`text-white`}>{email}</Small>
                                <Small css={tw`text-white`}>{address_street}</Small>
                                <Small css={tw`text-white`}>{address_city}</Small>
                            </div>
                            <Paragraph css={tw`text-lg mt-10`}>{disclaimer}</Paragraph>
                        </Column>

                        <Column css={tw`flex flex-col justify-between to-md:text-center`}>
                            <div>
                                <Markdown justify={false} blocks={_rawClauseText} />
                                <a
                                    href={clauseFile.asset.url}
                                    download={clauseFile.asset.originalFilename}
                                    css={tw`font-light!`}
                                >
                                    <MdFileDownload css={tw`inline-block mr-1`} />
                                    Pełna klauzula informacyjna do pobrania
                                </a>
                            </div>
                            <Small css={tw`mt-auto text-white mt-10`}>
                                {new Date().getFullYear()} © {copyright}
                            </Small>
                        </Column>
                    </Row>
                </Container>
            </Footer>
        </>
    );
};

export default Layout;
