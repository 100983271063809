import tw from 'twin.macro';

const Welcome = tw.section`
  md:leading-relaxed
  text-primary
  py-16
  to-md:text-lg text-xl
`;

export default Welcome;
